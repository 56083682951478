




















import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import EventActivityForm from '@/partials/forms/Events/EventActivityForm.vue'
import EventActivityAttachmentsTable from '@/partials/tables/Events/ActivityAttachments.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    EventActivityForm,
    EventActivityAttachmentsTable
  }
})
export default class EventActivityEdit extends Mixins(PermissionsMixin) {
  activity = {}
  done = false

  created () {
    axios.get(`/event/${this.$route.params.eventId}/activity/${this.$route.params.activityId}`)
      .then(response => {
        this.activity = camelCaseKeys(response.data.data, { deep: true })
      })
      .finally(() => { this.done = true })
  }
}
