






































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventActivityAttachmentsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) activityId!: string
  totalAttachments = 0
  attachments: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  selectedAttachment: any = {}

  fetchAttachments (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.eventId}/activity/${this.activityId}/attachment`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.attachments = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalAttachments = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Material de Apoio foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchAttachments()
  }

  get deleteUrl () {
    return isEmpty(this.selectedAttachment) ? '' : `event/${this.eventId}/activity/${this.activityId}/attachment/${this.selectedAttachment.id}`
  }

  search (searchString: string) {
    this.filteredData = this.attachments.filter((attachment: any) => {
      const allValues = Object.keys(attachment).map(key => attachment[key])

      const isMatch = allValues.some((value: string) => {
        return value.toLowerCase().includes(searchString.toLowerCase())
      })

      if (isMatch) {
        return attachment
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.fetchAttachments(pagination.page, pagination.length)
  }
}
